<template>
  <div class="water-meter">
    <el-tabs v-model="activeName">
      <el-tab-pane label="充值记录" name="pay">
        <pay-record></pay-record>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PayRecord from './payRecord.vue';

export default {
  data () {
    return {
      activeName: 'pay'
    };
  },
  components: { PayRecord },
  created () {

  },
  methods: {

  },
};
</script>

<style scoped lang='scss'>
.water-meter {
  background-color: #fff;
  padding: 10px;
}
</style>